




































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { Form, Progress } from "element-ui";
import FormItemBox from "@/components/sign/FormItemBox.vue";
import signjs from "@/components/sign/index";
import { subProjectFile, delProjectFile } from "@/components/sign/index";

@Component({
    components: {
        "el-form": Form,
        "al-form-item": FormItemBox,
        "el-progress": Progress
    }
})
export default class ProjectFileForm extends Vue {
    @Prop({ type: String, required: true, default: () => ({}) })
    childName!: string; //表单数据

    isshow: boolean = false;
    registItem_class_basic = "regist";
    get registForm() {
        let opt: object = [
            {
                ref: "file",
                formkey: "file",
                type: "uploadfile",
                classname: `${this.registItem_class_basic}-file`,
                label: this.$t("sign.file_label"),
                placeholder: this.$t("sign.file_placeholder")
            }
        ];
        return opt;
    }

    /* 表单数据 */
    registFormData = {
        file: []
    };

    /* 注册表单验证规则 */
    registFormRules = {
        contact: [
            { required: true, message: "联系人不能为空", trigger: "blur" }
        ]
    };

    //提交表单
    sub(formName: string, callback: any, isloading?: any) {
        signjs.validateForm(formName, this.$refs).then(res => {
            // console.log(this.registFormData);
            if (res) {
                isloading(); //打开loading遮罩层
                this.setFormData(this.registFormData).then((res: any) => {
                    this.getformData(callback(0, 0));

                    // subProjectFile(res, this.$route.params.pid).then(
                    //     (res: any) => {
                    //         this.$notify({
                    //             title: res.status === 0 ? "成功" : "失败",
                    //             message: res.msg,
                    //             type: res.status === 0 ? "success" : "error"
                    //         });
                    //         if (callback) {
                    //             callback(res.status, res.data);
                    //         } else {
                    //             console.error("Regist:No Callback");
                    //         }
                    //     }
                    // );
                });
            }
        });
    }

    //重置表单数据
    resetForm(formName: string) {
        //@ts-ignore
        signjs.resetForm(formName, this.$refs);
    }

    /* 提交前设置提交数据 */
    async setFormData(data: any) {
        const subdata = JSON.parse(JSON.stringify(data)).file;
        return subdata;
    }

    // 表单单项验证
    validItem(formname: string, formItem: string | object, callback: any) {
        signjs
            .validateFormItem(formname, formItem, this.$refs)
            .then((res: any) => {
                callback(res);
            });
    }

    /*
     *  获取表单数据
     */
    getformData(callback?: any) {
        if (this.$route.params.pid) {
            this.$axios({
                url: `http://ittn_data.ittn.com.cn/api/projectfile/${this.$route.params.pid}/index`,
                method: "get",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(
                        "api_token"
                    )}`,
                    lang: this.$i18n.locale
                }
            }).then((res: any) => {
                this.registFormData.file = res.data;
                this.isshow = true;
                if (callback) {
                    callback();
                }
            });
        } else {
            if (callback) {
                callback();
            }
        }
    }

    /**删除文件 */
    deleteFile(item: any, index: any) {
        this.$set(this.registFormData.file[index],"loading",true)
        delProjectFile(item.id).then((res: any) => {
            this.$set(this.registFormData.file[index],"loading",false)
            if (res.status == 0) {
                this.$notify({
                    type: "success",
                    title: "成功",
                    message: res.data
                });
                this.registFormData.file.splice(index, 1);
            } else {
                this.$notify({
                    type: "error",
                    title: "失败",
                    message: res.data
                });
            }
            
        });
    }

    test(formname?: string) {
        // this.registFormData = {};
    }
    mounted() {
        this.getformData();
    }
}
